import { render, staticRenderFns } from "./PerSalesStaffTable.vue?vue&type=template&id=6a65d093&scoped=true&"
import script from "./PerSalesStaffTable.vue?vue&type=script&lang=ts&"
export * from "./PerSalesStaffTable.vue?vue&type=script&lang=ts&"
import style0 from "./PerSalesStaffTable.vue?vue&type=style&index=0&id=6a65d093&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-93e13427ca/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a65d093",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../.yarn/__virtual__/vuetify-loader-virtual-1cdf2554cb/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})
